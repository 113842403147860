

//
// Nice Select.scss
//

.nice-select {
    .option{
        &.selected{
            font-weight: $font-weight-medium;
        }
    }
}
